/*Steps Styles*/
.ant-steps-dot {
  flex-wrap: wrap;
  overflow : hidden;
  padding  : 10px 0;

  & .ant-steps-item {
    margin-bottom: 10px;
  }

  & .ant-steps-item-description {
    @media screen and (max-width: @screen-xs-max) {
      display: none;
    }
  }
}

.@{class-prefix}-steps-mobile-sm {
  .ant-steps-item {
    &:not(.ant-steps-item-active) {
      .ant-steps-item-container {
        .ant-steps-item-content {
          .ant-steps-item-title {
            padding-left: 5px
          }
        }
      }
    }

    &.ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-tail {
          display: none !important;
        }

        .ant-steps-item-content {
          width   : 100%;
          overflow: visible;

          .ant-steps-item-title {
            width         : 85%;
            float         : right;
            padding-bottom: 1rem;
          }

          .ant-steps-item-description {
            clear: both;
          }
        }
      }
    }
  }
}
