@import "frontend-variables";
@import (css) url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap");

@default-frontend-font-size: 16px;

.@{class-prefix}-frontend-app {

  overflow-x: hidden;

   #frontend_logo {
      width        : 150px;
      margin-bottom: 0px;
   }

   .frontend-header-breadcrumbs {
      background-color: @lightblue-color;
      min-height      : 40px;

      @media (max-width: 600px) {
        min-height      : 30px;
      }
   }

   .frontend-header-bar {
      padding-top     : 48px;
      padding-bottom  : 100px;
      background-color: #003F73;
      margin-bottom   : 60px;
      position: relative;

      @media (max-width: 600px) {
        padding-top  : 20px;
        padding-bottom  : 20px;
        margin-bottom   : 20px;
      }

      #frontend-header-bar-dx {
        display:inline-block;
        position: absolute;
        background-color: #025BA7;
        width:50%;
        height:100%;
        left:50%;
        top:0;

        img {
          height: 100%;
        }
      }

      h1 {
         color      : @white-color;
         font-size  : 55px !important;
         line-height: 60px !important;
         font-weight: 400;
         position: relative;

         @media (max-width: 600px) {
          font-size  : 45px !important;
          line-height: 50px !important;
         }
      }

   }

   .frontend-container {
      font-family: "Source Sans Pro", sans-serif;
      color      : @primary-color  !important;
      margin     : 0 auto;
      max-width  : 1140px;
      padding    : 10px 14px 0px 14px;

      h2 {
         color      : @primary-color;
         font-size  : 39px !important;
         line-height: 39px !important;
         font-weight: 900 !important;

         @media (max-width: 600px) {
          font-size  : 29px !important;
          line-height: 29px !important;
         }
      }

      p {
         font-size  : @default-frontend-font-size;
         line-height: 24px;
         color      : @body-color  !important;
      }

      p,
      label,
      a,
      .frontend-input .ant-input {
         color: @body-color  !important;
      }

      ul {
         padding-left: 16px;
      }

   }

   a,
   a:active,
   a:link,
   a:hover {
      color: @primary-color  !important;
   }

   .@{class-prefix}-app-login-wrap-frontend {
      height    : auto !important;
      min-height: 100vh;
   }

   .@{class-prefix}-app-logo-frontend {
      text-align: left;
      padding   : 0 0 40px 0;
   }

   .@{class-prefix}-app-login-container-frontend {
      max-width: 540px !important;
   }


   .@{class-prefix}-app-login-form-container {
      background-color: @lightblue-o15-color;
   }


   .frontend-footer {
      margin-top      : 60px;
      padding         : 58px 0;
      background-color: #003F73;

      p {
         font-weight: 300;
         color      : @white-color  !important;
      }

      strong {
         font-size: 18px;
      }

      a {
         font-size      : @default-frontend-font-size;
         text-decoration: underline;
         color          : @white-color  !important;
         font-weight    : 600;
      }

      .credits {
         margin-top: 27px;

         p,
         a {
            font-size  : @default-frontend-font-size !important;
            font-weight: 300;
         }

      }

      .certificazioni {
         margin-top: 86px;
         max-width : 180px;
      }
   }


   label.form-label {
      strong {
         color: @primary-color;
      }
   }


   .@{class-prefix}-app-login-main-content-frontend {
      border-radius     : 0 !important;
      background-color  : #dbeef1 !important;
      z-index           : 9999 !important;
      box-shadow        : none !important;
      -webkit-box-shadow: none !important;
   }

   .@{class-prefix}-app-login-container-frontend .@{class-prefix}-app-login-content {
      width: 100% !important;
   }

   .frontend-btn-lg {
      padding         : 10px 16px !important;
      height          : auto !important;
      font-size       : @default-frontend-font-size !important;
      border          : 1px solid transparent !important;
      background-color: @lightblue-color  !important;
      color           : @white-color  !important;
   }

   .frontend-btn-lg:hover {
      border: 1px solid transparent !important;
   }

   .w-auto {
      width: auto !important;
   }

   .@{class-prefix}-arrow-back {
    font-size: 26px;
    position: relative;
    top:-8px;
    color: @body-color;
   }

   .@{class-prefix}-card-frontend {
     border-radius: 0;
     box-shadow: none;
     padding: 14px;

     a, .ant-form-item-explain-error {
       font-size:@default-frontend-font-size;
     }

     .ant-card-head-title {
       font-size: 20px;
       color: @body-color;
     }

     .ant-form-item-label > label {
       font-size:@default-frontend-font-size;
       color: @body-color;
     }

     .ant-form-item-control-input {
      .ant-form-item-control-input-content input,
      .ant-input,
      .ant-input-password {
       font-size:@default-frontend-font-size;
       border-radius: 0;
      }
     }

     .ant-input-group {
      .ant-input-group-addon,
      .ant-input-affix-wrapper {
        border-radius: 0;
        font-size:@default-frontend-font-size;
      }

     }
   }


   h3 {
    color      : @primary-color;
    font-size  : 20px !important;
    line-height: 26px !important;
    font-weight: 600 !important;
   }

   h4 {
    color      : @primary-color;
    font-size  : 18px !important;
    line-height: 22px !important;
    font-weight: 600 !important;
   }

   .@{class-prefix}-app-login-container-frontend .@{class-prefix}-app-login-content input {
      background-color: transparent !important;
      font-size       : @default-frontend-font-size;
   }

   .front-picker-date {
      width    : 100%;
      max-width: 300px;
   }

   .frontend-available-slots-container {
      width : 100%;
      margin: 0 0 30px 0;
   }

   .frontend-available-slots-container {
      .slot-container {
         width           : 100%;
         background-color: @white-color;
         border          : 1px solid @white-color;
         text-align      : center;
         border-radius   : 3px;
         color           : @primary-color;

         .slot-container-time {
            width    : 100%;
            padding  : 6px 2px;
            font-size: 20px;
            font-weight: 600;
            cursor   : pointer;
         }
      }

      .slot-container {
         &.selected {
            border          : 1px solid @secondary-color;
            background-color: @secondary-color;
            color           : @white-color;
         }
      }
   }

   textarea {
     font-size: @default-frontend-font-size;
   }

   .btn-grey {
      color           : @white-color !important;
      background-color: @body-color !important;
   }

   .btn-green {
      color           : @white-color !important;
      background-color: @secondary-color !important;
   }

   .btn-blue {
      color           : @white-color !important;
      background-color: @primary-color !important;
   }

   .btn-lightblue {
    color           : @white-color !important;
    background-color: @lightblue-color !important;
   }

   .underline {
      text-decoration: underline !important;
   }

   .frontend-input,
   .frontend-container textarea {
      background-color: @white-color;
      padding         : 6px 0 !important;
   }

   .frontend-container textarea {
      padding: 6px 10px !important;
   }

   .frontend-input .ant-input,
   .frontend-input .ant-input-affix-wrapper,
   .frontend-container textarea {
      border       : 0 !important;
      border-radius: 0 !important;
   }

   .bg-orange {
      background-color: @secondary-color  !important;
   }

   .bg-blue {
      background-color: @primary-color  !important;
   }

   .bg-green {
      background-color: #31a87c !important;
   }

   .bg-white-15 {
      background-color: rgba(255, 255, 255, 0.15) !important;
   }

   .btn-blue {
      background-color: #1490a3 !important;
   }

   .uppercase {
      text-transform: uppercase !important;
   }

   .border-0 {
      border: 0 !important;
   }

   .white {
      color: @white-color  !important;
   }

   .font-bold {
      font-weight: 600 !important;
   }

   h1 {
      font-size  : 26px !important;
      line-height: 26px !important;
   }


   .@{class-prefix}-app-login-wrap-frontend .anticon-check-circle {
      color: @white-color  !important;
   }

   .ant-form-item-explain-error {
      padding: 0 12px;
   }

   .color-blue {
      color: @primary-color  !important;
   }

   .color-orange {
      color: @secondary-color  !important;
   }

   .frontend-label-avatar {
      color      : @primary-color  !important;
      font-weight: 600 !important;
   }

   .frontend-header {
      box-shadow        : none !important;
      -webkit-box-shadow: none !important;
   }


   .@{class-prefix}-app-layout-frontend {
      background-color: @white-color  !important;
   }

   .frontend-btn-primary {
      text-transform  : none !important;
      background-color: @primary-color  !important;
      color           : @white-color  !important;
      font-weight     : 600 !important;
      font-size       : @default-frontend-font-size !important;
   }

   .frontend-table {
    .ant-table-thead th,
    td {
       background-color: transparent;
       color           : @body-color;
       font-size       : @default-frontend-font-size !important;
    }

    .ant-table-thead th {
      font-weight:600;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: @lightblue-o15-color;
    }

    .frontend-table-row {
      cursor: pointer;
    }
   }

   .@{class-prefix}-frontend-description {

    .ant-descriptions-row {
      .ant-descriptions-item-label {
        font-size: @default-frontend-font-size;
        font-weight: 600;
      }

      .ant-descriptions-item-content {
        background-color: @white-color;
        font-size: @default-frontend-font-size;
      }
    }

   }

   .@{class-prefix}-frontend-radio, .@{class-prefix}-frontend-select {
     font-size: @default-frontend-font-size;
   }

   .ant-picker-content > thead > tr > th, .ant-picker-calendar-date-value {
     font-size: @default-frontend-font-size;
   }


   .card-step {
      -webkit-border-radius: 0 !important;
      -moz-border-radius   : 0 !important;
      border-radius        : 0 !important;
      border               : 0 !important;
      background-color     : @lightblue-o15-color !important;
   }

   .ant-steps-item-active .ant-steps-item-icon {
      background-color: @primary-color  !important;
   }

   .w-100 {
      width: 100% !important;
   }


   .multiline-text {
      white-space: normal !important;
   }




   @media (max-width: 600px) {

      .frontend-available-slots-container .slot-container .slot-container-time {
         font-size: @default-frontend-font-size;
      }

      .ant-btn {
         white-space: normal !important;
      }

      .@{class-prefix}-avatar-name {
         display: none !important;
      }
   }

   @media (max-width: 360px) {
      .ant-picker-content {
         table-layout: auto !important;
      }
   }

}

.@{class-prefix}-frontend-user-popover {
  font-family: "Source Sans Pro", sans-serif;
  font-size: @default-frontend-font-size;
}


.frontend-font {
  font-family: "Source Sans Pro", sans-serif;
}


.@{class-prefix}-steps-mobile {

 .ant-steps-item {
   &:not(.ant-steps-item-active) {
     .ant-steps-item-container {
       .ant-steps-item-content {
         .ant-steps-item-title {
           padding-left: 5px
         }
       }
     }
   }

   &.ant-steps-item-active {
     .ant-steps-item-container {
       .ant-steps-item-tail {
         display: none !important;
       }

       .ant-steps-item-content {
         width   : 100%;
         overflow: visible;

         .ant-steps-item-title {
           width         : auto;
           float         : left;
           padding-bottom: 1rem;
         }

         .ant-steps-item-description {
           clear: both;
         }
       }
     }
   }
 }
}

