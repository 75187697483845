// ColorPicker Variables
@class-prefix: gx;

@white-color: #ffffff;
@black-color: #000000;
@body-color: #4E5E6E;

@lightblue-color: #228CE3;
@lightblue-o15-color: #228CE315;

//global style variables
@primary-color: #005CA7;
@secondary-color: #36B64B;
