.gx-flag {
    width: 20px;
}

.gx-flag.be {
    width: 18px;
}

.gx-flag.ch {
    width: 15px;
}

.gx-flag.mc {
    width: 19px;
}

.gx-flag.ne {
    width: 18px;
}

.gx-flag.np {
    width: 13px;
}

.gx-flag.va {
    width: 15px;
}

.gx-flag.ac {
    height             : 10px;
    background-position: 0px 0px;
}

.gx-flag.ad {
    height             : 14px;
    background-position: -22px 0px;
}

.gx-flag.ae {
    height             : 10px;
    background-position: -44px 0px;
}

.gx-flag.af {
    height             : 14px;
    background-position: -66px 0px;
}

.gx-flag.ag {
    height             : 14px;
    background-position: -88px 0px;
}

.gx-flag.ai {
    height             : 10px;
    background-position: -110px 0px;
}

.gx-flag.al {
    height             : 15px;
    background-position: -132px 0px;
}

.gx-flag.am {
    height             : 10px;
    background-position: -154px 0px;
}

.gx-flag.ao {
    height             : 14px;
    background-position: -176px 0px;
}

.gx-flag.aq {
    height             : 14px;
    background-position: -198px 0px;
}

.gx-flag.ar {
    height             : 13px;
    background-position: -220px 0px;
}

.gx-flag.as {
    height             : 10px;
    background-position: -242px 0px;
}

.gx-flag.at {
    height             : 14px;
    background-position: -264px 0px;
}

.gx-flag.au {
    height             : 10px;
    background-position: -286px 0px;
}

.gx-flag.aw {
    height             : 14px;
    background-position: -308px 0px;
}

.gx-flag.ax {
    height             : 13px;
    background-position: -330px 0px;
}

.gx-flag.az {
    height             : 10px;
    background-position: -352px 0px;
}

.gx-flag.ba {
    height             : 10px;
    background-position: -374px 0px;
}

.gx-flag.bb {
    height             : 14px;
    background-position: -396px 0px;
}

.gx-flag.bd {
    height             : 12px;
    background-position: -418px 0px;
}

.gx-flag.be {
    height             : 15px;
    background-position: -440px 0px;
}

.gx-flag.bf {
    height             : 14px;
    background-position: -460px 0px;
}

.gx-flag.bg {
    height             : 12px;
    background-position: -482px 0px;
}

.gx-flag.bh {
    height             : 12px;
    background-position: -504px 0px;
}

.gx-flag.bi {
    height             : 12px;
    background-position: -526px 0px;
}

.gx-flag.bj {
    height             : 14px;
    background-position: -548px 0px;
}

.gx-flag.bl {
    height             : 14px;
    background-position: -570px 0px;
}

.gx-flag.bm {
    height             : 10px;
    background-position: -592px 0px;
}

.gx-flag.bn {
    height             : 10px;
    background-position: -614px 0px;
}

.gx-flag.bo {
    height             : 14px;
    background-position: -636px 0px;
}

.gx-flag.bq {
    height             : 14px;
    background-position: -658px 0px;
}

.gx-flag.br {
    height             : 14px;
    background-position: -680px 0px;
}

.gx-flag.bs {
    height             : 10px;
    background-position: -702px 0px;
}

.gx-flag.bt {
    height             : 14px;
    background-position: -724px 0px;
}

.gx-flag.bv {
    height             : 15px;
    background-position: -746px 0px;
}

.gx-flag.bw {
    height             : 14px;
    background-position: -768px 0px;
}

.gx-flag.by {
    height             : 10px;
    background-position: -790px 0px;
}

.gx-flag.bz {
    height             : 14px;
    background-position: -812px 0px;
}

.gx-flag.ca {
    height             : 10px;
    background-position: -834px 0px;
}

.gx-flag.cc {
    height             : 10px;
    background-position: -856px 0px;
}

.gx-flag.cd {
    height             : 15px;
    background-position: -878px 0px;
}

.gx-flag.cf {
    height             : 14px;
    background-position: -900px 0px;
}

.gx-flag.cg {
    height             : 14px;
    background-position: -922px 0px;
}

.gx-flag.ch {
    height             : 15px;
    background-position: -944px 0px;
}

.gx-flag.ci {
    height             : 14px;
    background-position: -961px 0px;
}

.gx-flag.ck {
    height             : 10px;
    background-position: -983px 0px;
}

.gx-flag.cl {
    height             : 14px;
    background-position: -1005px 0px;
}

.gx-flag.cm {
    height             : 14px;
    background-position: -1027px 0px;
}

.gx-flag.cn {
    height             : 14px;
    background-position: -1049px 0px;
}

.gx-flag.co {
    height             : 14px;
    background-position: -1071px 0px;
}

.gx-flag.cp {
    height             : 14px;
    background-position: -1093px 0px;
}

.gx-flag.cr {
    height             : 12px;
    background-position: -1115px 0px;
}

.gx-flag.cu {
    height             : 10px;
    background-position: -1137px 0px;
}

.gx-flag.cv {
    height             : 12px;
    background-position: -1159px 0px;
}

.gx-flag.cw {
    height             : 14px;
    background-position: -1181px 0px;
}

.gx-flag.cx {
    height             : 10px;
    background-position: -1203px 0px;
}

.gx-flag.cy {
    height             : 14px;
    background-position: -1225px 0px;
}

.gx-flag.cz {
    height             : 14px;
    background-position: -1247px 0px;
}

.gx-flag.de {
    height             : 12px;
    background-position: -1269px 0px;
}

.gx-flag.dg {
    height             : 10px;
    background-position: -1291px 0px;
}

.gx-flag.dj {
    height             : 14px;
    background-position: -1313px 0px;
}

.gx-flag.dk {
    height             : 15px;
    background-position: -1335px 0px;
}

.gx-flag.dm {
    height             : 10px;
    background-position: -1357px 0px;
}

.gx-flag.do {
    height             : 13px;
    background-position: -1379px 0px;
}

.gx-flag.dz {
    height             : 14px;
    background-position: -1401px 0px;
}

.gx-flag.ea {
    height             : 14px;
    background-position: -1423px 0px;
}

.gx-flag.ec {
    height             : 14px;
    background-position: -1445px 0px;
}

.gx-flag.ee {
    height             : 13px;
    background-position: -1467px 0px;
}

.gx-flag.eg {
    height             : 14px;
    background-position: -1489px 0px;
}

.gx-flag.eh {
    height             : 10px;
    background-position: -1511px 0px;
}

.gx-flag.er {
    height             : 10px;
    background-position: -1533px 0px;
}

.gx-flag.es {
    height             : 14px;
    background-position: -1555px 0px;
}

.gx-flag.et {
    height             : 10px;
    background-position: -1577px 0px;
}

.gx-flag.eu {
    height             : 14px;
    background-position: -1599px 0px;
}

.gx-flag.fi {
    height             : 12px;
    background-position: -1621px 0px;
}

.gx-flag.fj {
    height             : 10px;
    background-position: -1643px 0px;
}

.gx-flag.fk {
    height             : 10px;
    background-position: -1665px 0px;
}

.gx-flag.fm {
    height             : 11px;
    background-position: -1687px 0px;
}

.gx-flag.fo {
    height             : 15px;
    background-position: -1709px 0px;
}

.gx-flag.fr {
    height             : 14px;
    background-position: -1731px 0px;
}

.gx-flag.ga {
    height             : 15px;
    background-position: -1753px 0px;
}

.gx-flag.gb {
    height             : 10px;
    background-position: -1775px 0px;
}

.gx-flag.gd {
    height             : 12px;
    background-position: -1797px 0px;
}

.gx-flag.ge {
    height             : 14px;
    background-position: -1819px 0px;
}

.gx-flag.gf {
    height             : 14px;
    background-position: -1841px 0px;
}

.gx-flag.gg {
    height             : 14px;
    background-position: -1863px 0px;
}

.gx-flag.gh {
    height             : 14px;
    background-position: -1885px 0px;
}

.gx-flag.gi {
    height             : 10px;
    background-position: -1907px 0px;
}

.gx-flag.gl {
    height             : 14px;
    background-position: -1929px 0px;
}

.gx-flag.gm {
    height             : 14px;
    background-position: -1951px 0px;
}

.gx-flag.gn {
    height             : 14px;
    background-position: -1973px 0px;
}

.gx-flag.gp {
    height             : 14px;
    background-position: -1995px 0px;
}

.gx-flag.gq {
    height             : 14px;
    background-position: -2017px 0px;
}

.gx-flag.gr {
    height             : 14px;
    background-position: -2039px 0px;
}

.gx-flag.gs {
    height             : 10px;
    background-position: -2061px 0px;
}

.gx-flag.gt {
    height             : 13px;
    background-position: -2083px 0px;
}

.gx-flag.gu {
    height             : 11px;
    background-position: -2105px 0px;
}

.gx-flag.gw {
    height             : 10px;
    background-position: -2127px 0px;
}

.gx-flag.gy {
    height             : 12px;
    background-position: -2149px 0px;
}

.gx-flag.hk {
    height             : 14px;
    background-position: -2171px 0px;
}

.gx-flag.hm {
    height             : 10px;
    background-position: -2193px 0px;
}

.gx-flag.hn {
    height             : 10px;
    background-position: -2215px 0px;
}

.gx-flag.hr {
    height             : 10px;
    background-position: -2237px 0px;
}

.gx-flag.ht {
    height             : 12px;
    background-position: -2259px 0px;
}

.gx-flag.hu {
    height             : 10px;
    background-position: -2281px 0px;
}

.gx-flag.ic {
    height             : 14px;
    background-position: -2303px 0px;
}

.gx-flag.id {
    height             : 14px;
    background-position: -2325px 0px;
}

.gx-flag.ie {
    height             : 10px;
    background-position: -2347px 0px;
}

.gx-flag.il {
    height             : 15px;
    background-position: -2369px 0px;
}

.gx-flag.im {
    height             : 10px;
    background-position: -2391px 0px;
}

.gx-flag.in {
    height             : 14px;
    background-position: -2413px 0px;
}

.gx-flag.io {
    height             : 10px;
    background-position: -2435px 0px;
}

.gx-flag.iq {
    height             : 14px;
    background-position: -2457px 0px;
}

.gx-flag.ir {
    height             : 12px;
    background-position: -2479px 0px;
}

.gx-flag.is {
    height             : 15px;
    background-position: -2501px 0px;
}

.gx-flag.it {
    height             : 14px;
    background-position: -2523px 0px;
}

.gx-flag.je {
    height             : 12px;
    background-position: -2545px 0px;
}

.gx-flag.jm {
    height             : 10px;
    background-position: -2567px 0px;
}

.gx-flag.jo {
    height             : 10px;
    background-position: -2589px 0px;
}

.gx-flag.jp {
    height             : 14px;
    background-position: -2611px 0px;
}

.gx-flag.ke {
    height             : 14px;
    background-position: -2633px 0px;
}

.gx-flag.kg {
    height             : 12px;
    background-position: -2655px 0px;
}

.gx-flag.kh {
    height             : 13px;
    background-position: -2677px 0px;
}

.gx-flag.ki {
    height             : 10px;
    background-position: -2699px 0px;
}

.gx-flag.km {
    height             : 12px;
    background-position: -2721px 0px;
}

.gx-flag.kn {
    height             : 14px;
    background-position: -2743px 0px;
}

.gx-flag.kp {
    height             : 10px;
    background-position: -2765px 0px;
}

.gx-flag.kr {
    height             : 14px;
    background-position: -2787px 0px;
}

.gx-flag.kw {
    height             : 10px;
    background-position: -2809px 0px;
}

.gx-flag.ky {
    height             : 10px;
    background-position: -2831px 0px;
}

.gx-flag.kz {
    height             : 10px;
    background-position: -2853px 0px;
}

.gx-flag.la {
    height             : 14px;
    background-position: -2875px 0px;
}

.gx-flag.lb {
    height             : 14px;
    background-position: -2897px 0px;
}

.gx-flag.lc {
    height             : 10px;
    background-position: -2919px 0px;
}

.gx-flag.li {
    height             : 12px;
    background-position: -2941px 0px;
}

.gx-flag.lk {
    height             : 10px;
    background-position: -2963px 0px;
}

.gx-flag.lr {
    height             : 11px;
    background-position: -2985px 0px;
}

.gx-flag.ls {
    height             : 14px;
    background-position: -3007px 0px;
}

.gx-flag.lt {
    height             : 12px;
    background-position: -3029px 0px;
}

.gx-flag.lu {
    height             : 12px;
    background-position: -3051px 0px;
}

.gx-flag.lv {
    height             : 10px;
    background-position: -3073px 0px;
}

.gx-flag.ly {
    height             : 10px;
    background-position: -3095px 0px;
}

.gx-flag.ma {
    height             : 14px;
    background-position: -3117px 0px;
}

.gx-flag.mc {
    height             : 15px;
    background-position: -3139px 0px;
}

.gx-flag.md {
    height             : 10px;
    background-position: -3160px 0px;
}

.gx-flag.me {
    height             : 10px;
    background-position: -3182px 0px;
}

.gx-flag.mf {
    height             : 14px;
    background-position: -3204px 0px;
}

.gx-flag.mg {
    height             : 14px;
    background-position: -3226px 0px;
}

.gx-flag.mh {
    height             : 11px;
    background-position: -3248px 0px;
}

.gx-flag.mk {
    height             : 10px;
    background-position: -3270px 0px;
}

.gx-flag.ml {
    height             : 14px;
    background-position: -3292px 0px;
}

.gx-flag.mm {
    height             : 14px;
    background-position: -3314px 0px;
}

.gx-flag.mn {
    height             : 10px;
    background-position: -3336px 0px;
}

.gx-flag.mo {
    height             : 14px;
    background-position: -3358px 0px;
}

.gx-flag.mp {
    height             : 10px;
    background-position: -3380px 0px;
}

.gx-flag.mq {
    height             : 14px;
    background-position: -3402px 0px;
}

.gx-flag.mr {
    height             : 14px;
    background-position: -3424px 0px;
}

.gx-flag.ms {
    height             : 10px;
    background-position: -3446px 0px;
}

.gx-flag.mt {
    height             : 14px;
    background-position: -3468px 0px;
}

.gx-flag.mu {
    height             : 14px;
    background-position: -3490px 0px;
}

.gx-flag.mv {
    height             : 14px;
    background-position: -3512px 0px;
}

.gx-flag.mw {
    height             : 14px;
    background-position: -3534px 0px;
}

.gx-flag.mx {
    height             : 12px;
    background-position: -3556px 0px;
}

.gx-flag.my {
    height             : 10px;
    background-position: -3578px 0px;
}

.gx-flag.mz {
    height             : 14px;
    background-position: -3600px 0px;
}

.gx-flag.na {
    height             : 14px;
    background-position: -3622px 0px;
}

.gx-flag.nc {
    height             : 10px;
    background-position: -3644px 0px;
}

.gx-flag.ne {
    height             : 15px;
    background-position: -3666px 0px;
}

.gx-flag.nf {
    height             : 10px;
    background-position: -3686px 0px;
}

.gx-flag.ng {
    height             : 10px;
    background-position: -3708px 0px;
}

.gx-flag.ni {
    height             : 12px;
    background-position: -3730px 0px;
}

.gx-flag.nl {
    height             : 14px;
    background-position: -3752px 0px;
}

.gx-flag.no {
    height             : 15px;
    background-position: -3774px 0px;
}

.gx-flag.np {
    height             : 15px;
    background-position: -3796px 0px;
}

.gx-flag.nr {
    height             : 10px;
    background-position: -3811px 0px;
}

.gx-flag.nu {
    height             : 10px;
    background-position: -3833px 0px;
}

.gx-flag.nz {
    height             : 10px;
    background-position: -3855px 0px;
}

.gx-flag.om {
    height             : 10px;
    background-position: -3877px 0px;
}

.gx-flag.pa {
    height             : 14px;
    background-position: -3899px 0px;
}

.gx-flag.pe {
    height             : 14px;
    background-position: -3921px 0px;
}

.gx-flag.pf {
    height             : 14px;
    background-position: -3943px 0px;
}

.gx-flag.pg {
    height             : 15px;
    background-position: -3965px 0px;
}

.gx-flag.ph {
    height             : 10px;
    background-position: -3987px 0px;
}

.gx-flag.pk {
    height             : 14px;
    background-position: -4009px 0px;
}

.gx-flag.pl {
    height             : 13px;
    background-position: -4031px 0px;
}

.gx-flag.pm {
    height             : 14px;
    background-position: -4053px 0px;
}

.gx-flag.pn {
    height             : 10px;
    background-position: -4075px 0px;
}

.gx-flag.pr {
    height             : 14px;
    background-position: -4097px 0px;
}

.gx-flag.ps {
    height             : 10px;
    background-position: -4119px 0px;
}

.gx-flag.pt {
    height             : 14px;
    background-position: -4141px 0px;
}

.gx-flag.pw {
    height             : 13px;
    background-position: -4163px 0px;
}

.gx-flag.py {
    height             : 11px;
    background-position: -4185px 0px;
}

.gx-flag.qa {
    height             : 8px;
    background-position: -4207px 0px;
}

.gx-flag.re {
    height             : 14px;
    background-position: -4229px 0px;
}

.gx-flag.ro {
    height             : 14px;
    background-position: -4251px 0px;
}

.gx-flag.rs {
    height             : 14px;
    background-position: -4273px 0px;
}

.gx-flag.ru {
    height             : 14px;
    background-position: -4295px 0px;
}

.gx-flag.rw {
    height             : 14px;
    background-position: -4317px 0px;
}

.gx-flag.sa {
    height             : 14px;
    background-position: -4339px 0px;
}

.gx-flag.sb {
    height             : 10px;
    background-position: -4361px 0px;
}

.gx-flag.sc {
    height             : 10px;
    background-position: -4383px 0px;
}

.gx-flag.sd {
    height             : 10px;
    background-position: -4405px 0px;
}

.gx-flag.se {
    height             : 13px;
    background-position: -4427px 0px;
}

.gx-flag.sg {
    height             : 14px;
    background-position: -4449px 0px;
}

.gx-flag.sh {
    height             : 10px;
    background-position: -4471px 0px;
}

.gx-flag.si {
    height             : 10px;
    background-position: -4493px 0px;
}

.gx-flag.sj {
    height             : 15px;
    background-position: -4515px 0px;
}

.gx-flag.sk {
    height             : 14px;
    background-position: -4537px 0px;
}

.gx-flag.sl {
    height             : 14px;
    background-position: -4559px 0px;
}

.gx-flag.sm {
    height             : 15px;
    background-position: -4581px 0px;
}

.gx-flag.sn {
    height             : 14px;
    background-position: -4603px 0px;
}

.gx-flag.so {
    height             : 14px;
    background-position: -4625px 0px;
}

.gx-flag.sr {
    height             : 14px;
    background-position: -4647px 0px;
}

.gx-flag.ss {
    height             : 10px;
    background-position: -4669px 0px;
}

.gx-flag.st {
    height             : 10px;
    background-position: -4691px 0px;
}

.gx-flag.sv {
    height             : 12px;
    background-position: -4713px 0px;
}

.gx-flag.sx {
    height             : 14px;
    background-position: -4735px 0px;
}

.gx-flag.sy {
    height             : 14px;
    background-position: -4757px 0px;
}

.gx-flag.sz {
    height             : 14px;
    background-position: -4779px 0px;
}

.gx-flag.ta {
    height             : 10px;
    background-position: -4801px 0px;
}

.gx-flag.tc {
    height             : 10px;
    background-position: -4823px 0px;
}

.gx-flag.td {
    height             : 14px;
    background-position: -4845px 0px;
}

.gx-flag.tf {
    height             : 14px;
    background-position: -4867px 0px;
}

.gx-flag.tg {
    height             : 13px;
    background-position: -4889px 0px;
}

.gx-flag.th {
    height             : 14px;
    background-position: -4911px 0px;
}

.gx-flag.tj {
    height             : 10px;
    background-position: -4933px 0px;
}

.gx-flag.tk {
    height             : 10px;
    background-position: -4955px 0px;
}

.gx-flag.tl {
    height             : 10px;
    background-position: -4977px 0px;
}

.gx-flag.tm {
    height             : 14px;
    background-position: -4999px 0px;
}

.gx-flag.tn {
    height             : 14px;
    background-position: -5021px 0px;
}

.gx-flag.to {
    height             : 10px;
    background-position: -5043px 0px;
}

.gx-flag.tr {
    height             : 14px;
    background-position: -5065px 0px;
}

.gx-flag.tt {
    height             : 12px;
    background-position: -5087px 0px;
}

.gx-flag.tv {
    height             : 10px;
    background-position: -5109px 0px;
}

.gx-flag.tw {
    height             : 14px;
    background-position: -5131px 0px;
}

.gx-flag.tz {
    height             : 14px;
    background-position: -5153px 0px;
}

.gx-flag.ua {
    height             : 14px;
    background-position: -5175px 0px;
}

.gx-flag.ug {
    height             : 14px;
    background-position: -5197px 0px;
}

.gx-flag.um {
    height             : 11px;
    background-position: -5219px 0px;
}

.gx-flag.us {
    height             : 11px;
    background-position: -5241px 0px;
}

.gx-flag.uy {
    height             : 14px;
    background-position: -5263px 0px;
}

.gx-flag.uz {
    height             : 10px;
    background-position: -5285px 0px;
}

.gx-flag.va {
    height             : 15px;
    background-position: -5307px 0px;
}

.gx-flag.vc {
    height             : 14px;
    background-position: -5324px 0px;
}

.gx-flag.ve {
    height             : 14px;
    background-position: -5346px 0px;
}

.gx-flag.vg {
    height             : 10px;
    background-position: -5368px 0px;
}

.gx-flag.vi {
    height             : 14px;
    background-position: -5390px 0px;
}

.gx-flag.vn {
    height             : 14px;
    background-position: -5412px 0px;
}

.gx-flag.vu {
    height             : 12px;
    background-position: -5434px 0px;
}

.gx-flag.wf {
    height             : 14px;
    background-position: -5456px 0px;
}

.gx-flag.ws {
    height             : 10px;
    background-position: -5478px 0px;
}

.gx-flag.xk {
    height             : 15px;
    background-position: -5500px 0px;
}

.gx-flag.ye {
    height             : 14px;
    background-position: -5522px 0px;
}

.gx-flag.yt {
    height             : 14px;
    background-position: -5544px 0px;
}

.gx-flag.za {
    height             : 14px;
    background-position: -5566px 0px;
}

.gx-flag.zm {
    height             : 14px;
    background-position: -5588px 0px;
}

.gx-flag.zw {
    height             : 10px;
    background-position: -5610px 0px;
}

.gx-flag {
    width              : 20px;
    height             : 15px;
    box-shadow         : 0px 0px 1px 0px #888;
    background-image   : url("/src/assets/images/flags.png");
    background-repeat  : no-repeat;
    background-color   : #DBDBDB;
    background-position: 20px 0;
}

// @media only screen and (-webkit-min-device-pixel-ratio: 2),
// only screen and (min--moz-device-pixel-ratio: 2),
// only screen and (-o-min-device-pixel-ratio: 2 / 1),
// only screen and (min-device-pixel-ratio: 2),
// only screen and (min-resolution: 192dpi),
// only screen and (min-resolution: 2dppx) {
//     .gx-flag {
//         background-size: 5630px 15px;
//     }
// }


// @media only screen and (-webkit-min-device-pixel-ratio: 2),
// only screen and (min--moz-device-pixel-ratio: 2),
// only screen and (-o-min-device-pixel-ratio: 2 / 1),
// only screen and (min-device-pixel-ratio: 2),
// only screen and (min-resolution: 192dpi),
// only screen and (min-resolution: 2dppx) {
//     .gx-flag {
//         background-image: url("/src/assets/images/flags@2x.png");
//     }
// }

.gx-flag.np {
    background-color: transparent;
}